import React from "react";

const Trends = () => {
  return (
    <div className="trend">
      <h3>Post title</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni,
        perspiciatis...
      </p>
    </div>
  );
};

export default Trends;
